<script setup lang="ts">
import type { IManagerFieldEmits, IManagerFieldProps } from './types'
import {
  useFieldCache,
  useFieldCalculation,
  useFieldCast,
  useField,
  useFieldData,
} from '@manager'
import type { GoogleAddressPrediction } from '~/types/googleAddress'
import formkitTheme from '@ui/formkit/formkit.theme'

type DataAddress = {
  propertyName?: string | null
  address?: string | null
  suburb?: string | null
  postcode?: string | null
  country?: string | null
  state?: string | null
} | null

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
  parentPath: '',
})
const emit = defineEmits<IManagerFieldEmits>()

const {
  modelValue,
  parentValue,
  id,
  type,
  colSize,
  label,
  path,
  validation: { errorMessage },
  help,
  disabled,
  min,
  max,
  handleChange,
  handleBlur,
} = useField(props, emit)

const listboxRef = ref()
// Update internal search
tryOnActivated(() => {
  if (modelValue.value) {
    listboxRef.value?.updateSearch(modelValue.value)
  }
})

const { cast, castPlugin } = useFieldCast(props.node)

// Initialize calculated field, after setting default value
const { registerCalculated } = useFieldCalculation(props.node, parentValue)
registerCalculated()

const addressData = ref<NonNullable<DataAddress>>({
  propertyName: null,
  address: null,
  suburb: null,
  postcode: null,
  country: null,
  state: null,
})
const setAddressObject = (address: DataAddress) => {
  addressData.value = {
    propertyName: address?.propertyName || null,
    address: address?.address || null,
    suburb: address?.suburb || null,
    postcode: address?.postcode || null,
    country: address?.country || null,
    state: address?.state || null,
  }
}

// Initialize data field, after setting default value
const { registerData } = useFieldData(props.node, parentValue, addressData)
registerData()

useFieldCache(props.node, parentValue, props.preserveCache)

const { getPlacePredictions, getPlaceDetails } = useGoogleAddressAutoComplete()

const addressPredictions = ref<GoogleAddressPrediction[]>([])
const search = async (query: string) => {
  // If the value is the same, don't update
  if (query === modelValue.value) return

  // Update modelValue
  modelValue.value = query || null
  handleChange(query || null, !!query)
  // Clear address object
  setAddressObject(null)

  if (query) {
    addressPredictions.value = await getPlacePredictions(query)
  } else {
    addressPredictions.value = []
  }
}

const onSelect = (address: GoogleAddressPrediction | undefined) => {
  if (address === undefined) return
  const newValue = address.description
  // Update modelValue
  modelValue.value = newValue
  handleChange(newValue)
  // Update internal search
  listboxRef.value?.updateSearch(newValue)
  // Clear predictions
  addressPredictions.value = []

  // a valid detailed address is fetched when user selects an address from the options
  getPlaceDetails(address.place_id, (addr) => {
    // Update address object
    setAddressObject({
      propertyName: addr.name,
      address: addr.formatted_address,
      suburb: addr.parsed?.long.locality,
      postcode: addr.parsed?.long.postal_code,
      country: addr.parsed?.short.country,
      state: addr.parsed?.long.administrative_area_level_1,
    })
  })
}
</script>

<template>
  <ManagerSharedFieldBase
    :path="path"
    :col-size="colSize"
    :tooltip="node.tooltip"
    :help="help"
    :error-message="errorMessage"
    :usage="node.usage"
  >
    <div
      :class="[formkitTheme.listbox.outer, 'w-full', disabled && 'opacity-50']"
    >
      <div>
        <label :class="formkitTheme.global.label" :for="id">
          {{ label }}
        </label>
        <div :class="formkitTheme.listbox.inner">
          <FormListbox
            :id="id"
            ref="listboxRef"
            :name="node.name"
            :options="addressPredictions"
            format="option"
            label-by="description"
            track-by="place_id"
            outer-class="w-full"
            label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
            :plugins="[castPlugin]"
            :searchable="true"
            :internal-search="false"
            :preserve-search="true"
            :clear-on-select="false"
            :disabled="disabled"
            empty-message="Nothing found."
            @search="search"
            @select="onSelect"
            @close="handleBlur"
          >
            <template #selection> {{ modelValue }} </template>
            <template #placeholder> {{ modelValue }} </template>
          </FormListbox>
        </div>
      </div>
    </div>
  </ManagerSharedFieldBase>
</template>

<style lang="postcss" scoped>
:deep(.multiselect) {
  .multiselect__placeholder,
  .multiselect__input {
    @apply text-gray-100;
  }
}
</style>
